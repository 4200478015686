.navbar {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  background: #ededed;
  box-sizing: border-box;
  height: 44px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 20px;
}

.nav_item {
  height: 80%;
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
}
