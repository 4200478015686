$toggle-background-off: #9e9e9e;
$toggle-background-on: #0078d4;
$button-movement-length: 18px;

.switch {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  transition: 0.2s;

  &_is-off {
    background: $toggle-background-off;
  }
  &_is-on {
    background: $toggle-background-on;
  }

  & .toggle-button {
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    transition: 0.15s;

    &_position-right {
      transform: translateX($button-movement-length);
    }

    &_position-left {
      transform: translateX(0);
    }
  }
}
