.table_container {
  height: 85vh;
  background-color: white;
  border-radius: 4px;
  position: relative;

  & .table_header {
    border-bottom: 1px solid #94cfe1;
    position: sticky;
    top: 0;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  & table {
    border: none !important;
    border-radius: 4px !important;
    font-weight: 500;
  }
  & thead th {
    font-size: 14px;
    font-weight: bolder;
    border: none !important;
    padding: 14px !important;
    vertical-align: middle;
  }

  & thead tr {
    position: relative;
  }

  // actions column
  & thead tr .actions {
    float: right;
  }

  //   id column
  & thead tr th:first-child {
    float: left;
    margin-left: 1rem !important;
    margin-top: 0.3rem;
  }

  & tbody tr:nth-child(odd) {
    background: #e2ebee;
    border: none;
  }

  & tbody td {
    padding: 14px !important;
    border: none !important;
  }

  & tbody tr:hover {
    background: #9ae1ff !important;
    border: none;
  }

  & .action_cell {
    min-width: 73px;
    float: right;
  }

  & .id_cell {
    float: left;
    margin-left: 1rem !important;
  }

  & .react-bootstrap-table {
    justify-content: space-between;
    overflow: scroll;
    height: 100%;
  }
}

.react-bootstrap-table-sort-order,
.order {
  display: flex;
  flex-wrap: nowrap;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.table_pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px 2px;
  border-radius: 4px;
  padding: 2px;

  & .page_size_changer {
    display: flex;
    flex-direction: row;
    align-items: center;

    & button {
      border: 0.1px solid #dee2e6;
      background: #9ae1ff;
      border-color: #3c8dbc;
      color: inherit;
    }

    & p {
      margin-right: 1rem;
      font-weight: 600;
    }
  }

  & .caret {
    display: none;
  }

  & .page-item.active .page-link {
    background: #9ae1ff;
    border-color: #3c8dbc;
    color: inherit;
  }

  & .pagination {
    min-width: fit-content;
    & li a {
      color: black;
      text-align: center;
    }

    & .active {
      font-weight: bold;
    }
  }

  & .custom-page-dropwown {
    & .dropdown-menu {
      width: 100% !important;
      position: absolute;
      top: -7rem;
      left: 0.5rem;
      width: 40px !important;
      min-width: 40px !important;

      & .dropdown-item {
        width: 100% !important;
        text-align: center;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
