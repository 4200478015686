.archive_cnt {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: -10px;
  background: rgb(136, 133, 128);
  border-radius: 50%;
  color: white !important;
}

.archive_opener {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.action_bar {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.empty_mode {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem !important;
}

.container {
  border: 1.5px dotted grey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.active_fields {
  width: 100%;
  margin-bottom: -1.5rem !important;

  & > div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 22px;
    margin-bottom: 13px;

    & label {
      width: 40% !important;
    }

    & input {
      width: 70%;
    }
  }
}
