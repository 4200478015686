.container {
  display: flex;
  width: 100% !important;
  padding: 1rem 0;
  flex-direction: column;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btn {
  & span {
    color: black;
  }
  padding: 0;
  margin-bottom: 10px;
  margin: 0 auto;
}

.with_delete_btn {
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  border: 2px dashed rgb(0 0 0 / 10%);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px !important;

  &:hover {
    border: 2px dashed rgb(0 0 0 / 70%);
    box-shadow: none;
  }

  & .input_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 22px;
    margin-bottom: 13px;
  }

  & input {
    width: 100%;
    margin: 0;
  }

  & .delete_btn {
    padding: 0;
    margin-bottom: 10px;
    position: absolute;
    border: none;
    background: none;
    top: 10px;
    right: -30px;
  }
}
