@import 'styles/variables';

.modal-content > div {
  border: none;
}

.modal_btn {
  width: 100%;
}

.delete_buttons {
  width: 100%;
  justify-content: space-evenly;

  & button {
    width: 192px;
  }
}

.modal_form {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 1rem 0;

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  & label {
    margin-bottom: 5px;
  }

  & input,
  & select,
  & textarea {
    height: 32px;
    border-radius: 4px;
    font-size: 16px;
    border: 2px solid #c4c4c4;
    background: white;
    padding: 0.5rem;
    height: fit-content;
  }

  & input[type='radio'] {
    width: 25px;
  }

  & textarea {
    height: 50px;
  }

  & input[type='checkbox'] {
    border: 2px solid;
    width: 20px !important;
  }

  & input:disabled {
    background: #e2ebee;
  }

  & .archived_fields {
    background: #e5e5e5;
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 24px;

    & .archive_label {
      font-weight: bolder;
    }

    & input {
      padding: 1rem;
    }
  }
}

.form_container {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  height: fit-content;
  padding: 1rem 0;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;

  padding: 2rem 3rem;

  & > div,
  & .archived_fields > div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 22px;
    margin-bottom: 13px;

    & label {
      width: 40% !important;
    }

    & input {
      width: 70%;
    }
  }

  & > .btn {
    width: 100%;
    border: 2px solid;
  }

  & label {
    font-size: 14px;
    font-weight: 600;
    color: #455460;
    margin: 0;
  }

  & span {
    color: red;
    font-size: 12px;
    margin-left: 5px;
  }

  & input,
  & select,
  & textarea {
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    border: 2px solid #c4c4c4;
    background: none;
    width: 100% !important;
    margin: 0;
  }

  & textarea {
    height: 50px;
  }

  & input[type='checkbox'] {
    border: 2px solid;
    width: 20px !important;
  }

  & input:disabled {
    background: #e2ebee;
  }

  & .archived_fields {
    background: #e5e5e5;
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 24px;

    & .archive_label {
      font-weight: bolder;
    }

    & input {
      padding: 1rem;
    }
  }

  & .archived_fields {
    border: 2px dashed grey;
    display: flex;
    flex-direction: column;
  }
}

.delete_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    justify-content: space-between;
  }

  & p {
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 160%;
    & strong {
      font-weight: 600;
    }
  }
}

.react-date-picker {
  width: 99%;
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;

  &__wrapper {
    border: none !important;
  }

  & input {
    padding: 0 1.5rem;
    margin: 0;
    width: fit-content !important;
    max-width: 30px;

    &:focus {
      background: white;
    }
  }
}

div.nav-item .nav-link.active,
div.nav-item .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: white !important;
  border: none;
}
