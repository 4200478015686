@import 'styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import 'styles/main';

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  color: map-get($colors, 'main_color');
  background: map-get($colors, 'main_background');
  width: 100%;
}

.container {
  min-width: 100%;
}

.main_block {
  background: #ffffff;
  box-shadow: 0px 16px 44px rgba(87, 138, 214, 0.22);
}

.modal-header .close {
  display: flex;
  font-size: 50px;
  font-weight: 100;
  line-height: 2rem;
}

.imageWrap {
  max-width: 13.4667rem;
  max-height: 9rem;
}

.news_body {
  overflow-y: hidden;
  line-height: 1.3333rem;
  height: 6.5333rem;
}

@media (max-width: 768px) {
  .search-label {
    width: 90%;
  }
}

.tabs-nav {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 25px;
  width: 100%;
  > div > a {
    outline: none;
  }
}

div.nav-item .nav-link.active,
div.nav-item .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #1a202c;
  font-weight: 600;
  background-color: transparent;
  border-color: #f9f9f9;
  border-bottom: 2px solid #162938;
}

.show > .btn-primary.dropdown-toggle {
  color: #6881e7 !important;
}
