.loader_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(66, 66, 66, 0.1);
  z-index: 9999999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
