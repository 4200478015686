.arrow_btn {
  background: transparent !important;
  width: 32px;
  height: 100%;
  border: none;
  outline: none;
}

.arrow_disabled {
  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
}

.container {
  background: #ededed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollable_container {
  width: 94%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  // overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
  padding: 3px 0;

  & a {
    // margin-bottom: -4px;
    //height: 90% !important;
  }
}
