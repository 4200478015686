.form-container {
  display: flex;
  width: fit-content;
  padding: 25px 40px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(51, 51, 51, 0.06);

  & h2 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  & form {
    display: flex;
    flex-direction: column;

    & label {
      font-size: 12px;
      color: #455460;
      margin-bottom: 6px;
    }

    & span {
      color: red !important;
    }
  }

  & span {
    color: red !important;
  }

  & form input,
  & form textarea {
    min-width: 380px;
    min-height: 32px;
    margin-bottom: 24px;
    background-color: #f8f8f9;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid #eaecee;
  }
}
