@import 'styles/variables';

.modal-content > div {
  border: none;
}

.modal_btn {
  width: 100%;
}

.delete_buttons {
  width: 100%;
  justify-content: space-evenly;

  & button {
    width: 192px;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;

  & label {
    font-size: 12px;
    color: #455460;
    margin-bottom: 6px;
  }

  & input,
  select {
    min-height: 32px;
    margin-bottom: 24px;
    background-color: #f8f8f9;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid #eaecee;
  }
}

.delete_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    justify-content: space-between;
  }

  & p {
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 160%;
    & strong {
      font-weight: 600;
    }
  }
}
